footer{
    background-color: #00353F;
    /* position: absolute; */
    width: 100%;
    /* height: auto; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    justify-content: space-around;
}

h3{
    /* font-family: 'Montserrat',
    sans-serif; */
    color: white;
    margin: auto;
}

.footer-copyright{
    padding: 15px 50px;
    /* margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 25%;
    margin-left: 15%; */
}

.icons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-self: center;
    /* display: inline-flex;
    margin: auto; */
}

.icons div .icones{
    font-size: 15px;
}

@media only screen and (min-width: 1024px) {
    footer {
        width: 100%;
        height: auto;
    }

    h3 {
        margin: auto;
    }

    .footer-copyright {
/*         margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 25%;
        margin-left: 15%; */
    }

    .icons {
        /* margin: auto; */
    }
}

@media only screen and (min-width: 768px) {

    footer {
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        height: auto;
    }

    h3 {
        margin: auto;
    }

    .footer-copyright {
/*         margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 25%;
        margin-left: 15%; */
    }

    .icons {
        grid-template-columns: repeat(2, 1fr);
        /* margin: auto; */
    }
}

@media only screen and (max-width: 468px) {
    .icons {
        grid-template-columns: repeat(2, 1fr);
        /* margin: auto; */
    }

    h3 {
        margin: auto;
        font-size: 15px;
    }
}
