main.conteudo {
  background-image: url("../../_assets/img/outras imagens/white\ back.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
}

section.conteudo-projetos {
  height: auto;
}

section.cards-projetos {
  display: grid;
  grid-gap: 1rem;
  text-align: center;
}

section.cards-projetos > h3 {
  font-family: "Montserrat";
  font-style: normal;
  color: #00353f;
  font-size: 25px;
  line-height: 37px;
  font-weight: 1000;
  text-align: center;
}

.cards-projetos {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.titulo-principal {
  align-items: center;
  justify-content: center;
  display: flex;
}

.titulo-principal > h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 59px;
  text-align: justify;
  color: #00353f;
}

.linha-titulo {
  align-items: center;
  justify-content: center;
  display: flex;
}

.linha-titulo > hr {
  width: 28%;
  border: 1px solid #00353f;
  color: #00353f;
  margin-bottom: 5%;
}

.desenvolvimento {
  width: 400px;
  height: auto;
  padding: 1rem;
  margin-bottom: 50px;
}

.desenvolvimento > img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 380px;
  height: 267px;
}

.desenvolvimento > p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: justify;
}

.desenvolvimento > h3 {
  font-family: "Montserrat";
  font-style: normal;
  color: #00353f;
  font-size: 25px;
  line-height: 37px;
  font-weight: 1000;
  text-align: center;
  margin-bottom: 20px;
}

@media (min-width: 1024px) {
  .conteudo-projetos {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 430px) {
  .conteudo-projetos {
    grid-template-columns: repeat(2, 1fr);
  }
  .desenvolvimento > img {
    width: 300px;
    height: 240px;
  }
  .cards-projetos {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: -webkit-fill-available;
  }
}
