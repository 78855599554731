@font-face {
    font-family: "Montserrat";
    src: url("./_assets/font/Montserrat-VariableFont_wght.ttf");
}
@font-face {
    font-family: "Montserrat";
    src: url("./_assets/font/static/Montserrat-Medium.ttf");
    font-weight: 500;
}
@font-face {
    font-family: "Montserrat";
    src: url("./_assets/font/static/Montserrat-Bold.ttf");
    font-weight: bold;
}
@font-face {
    font-family: "Montserrat";
    src: url("./_assets/font/static/Montserrat-ExtraBold.ttf");
    font-weight: 800;
}
@font-face {
    font-family: "Montserrat";
    src: url("./_assets/font/static/Montserrat-Italic.ttf");
    font-style: italic;
}

body {
    font-family: "Montserrat";
}