section.contato{
    background: #006F77;
    width: 100%;
    padding: 20px 0;
}

span.h3{
    color: white;
}

.cards {
    display: flex;
    text-align: center;
}

.fale-conosco{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: space-around;
    flex: 1;
}

.fale-conosco > h1 {
    color: white;
    flex-grow: 3;
    width: 100%;
    padding-bottom: 15px;
}

.fale-conosco .contatoDiv{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.fale-conosco .contatoDiv .contatoLink {
    padding: 1rem;
    display: flex;
    border-radius: 1em;
    text-decoration: none;
    background-color: #03b3ae; /* #00d8d2 */
    align-items: center;
    margin-bottom: 10px;
    min-width: 400px;
    justify-content: center;
}

.fale-conosco .contatoDiv .contatoLink .icone {
    font-size: 30px;
    padding-right: 10px;
}
.fale-conosco .contatoDiv .contatoLink span{
    margin: 0;
}

@media (min-width: 1024px) {
    section.contato {
        height: auto;
    }
}

@media (max-width: 460px) {
    .fale-conosco {
        margin-top: 50px;
    }
}