main.conteudo {
  background-image: url("../../_assets/img/outras imagens/white\ back.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  height: auto;
}

section.conteudo-projetos {
  justify-content: center;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.titulo-principal {
  align-items: center;
  justify-content: center;
  display: flex;
}

.linha-titulo {
  align-items: center;
  justify-content: center;
  display: flex;
}

.linha-titulo > hr {
  width: 45%;
  border: 1px solid #00353f;
  color: #00353f;
  margin-bottom: 5%;
}

.cards-projetos {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.btn-download {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.btn-success {
  color: white;
  background-color: #00353f;
  width: 141px;
  height: 47px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: justify;
}

.pdf {
  width: 420px;
  position: absolute;
  height: 540px;
  margin-top: 40px;
}

.pdf .texto {
  font-size: 16px;
  display: flex;
  text-align: center;
  align-items: center;
  height: 540px;
  overflow: scroll;
  overflow-x: hidden;
  align-content: flex-start;
  flex-wrap: wrap;
}

.download {
  text-decoration: none;
  color: white;
}

@media (max-width: 505px) {
  .tablet {
    width: 380px;
  }

  .titulo-principal > h3 {
    font-size: 20px;
  }

  .pdf .texto {
    width: 320px !important;
    height: 390px !important;
    margin: 0 auto;
  }
}

@media (max-width: 375px) {
  .tablet {
    width: 350px;
  }

  .titulo-principal > h3 {
    font-size: 20px;
  }

  .pdf .texto {
    width: 320px !important;
    height: 370px !important;
    margin: 0 auto;
  }
}
