section.depoimentos .titulo-secao span {
    font-size: 48px;
    font-style: normal;
    text-align: center;
}

section.depoimentos .linha-borda {
    justify-content: center;
}

section.depoimentos .linha-borda .linha-verde {
    border-bottom: 5px solid #006F77;
    width: 300px;
}

section.depoimentos #carrosselDepoimentos .carousel-indicators {
    margin-right: 20%;
    margin-bottom: 1rem;
    margin-left: 20%;
}

section.depoimentos #carrosselDepoimentos .carousel-indicators button {
    width: 20px;
    height: 20px;
    opacity: 1;
    border: 3px solid #fff;
    background-clip: inherit;
    background-color: #C4C4C4;
}

section.depoimentos #carrosselDepoimentos .carousel-indicators button.active {
    background-color: #006F77;
    border: 3px solid #fff;
}
section.depoimentos #carrosselDepoimentos .carousel-item {
    margin-bottom: 50px;
}
section.depoimentos #carrosselDepoimentos .carousel-item p.texto{
    font-size: 30px;
    font-weight: 400;
    color: #00353F;
    overflow: hidden;
}
section.depoimentos #carrosselDepoimentos .carousel-item p.autor-nome{
    font-size: 30px;
    font-weight: 600;
    color: #00353F;
}
section.depoimentos #carrosselDepoimentos .carousel-item p.funcao-autor{
    font-size: 24px;
    font-weight: 400;
    color: #00353F;
}

@media (max-width: 992px) {
    section.depoimentos #carrosselDepoimentos .carousel-item p.texto {
        font-size: 24px;
    }
}
@media (max-width: 578px) {
    section.depoimentos #carrosselDepoimentos .carousel-item p.texto {
        font-size: 20px;
    }
}
@media (max-width: 448px) {
    section.depoimentos .titulo-secao span {
        font-size: 30px;
    }
    section.depoimentos #carrosselDepoimentos .carousel-item p.texto {
        font-size: 15px;
    }
}