.box{
    display: flex;
    /* flex-direction: row;
    flex-wrap: wrap;
    justify-content:center; */

    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-start;

    background-color: #F9FBFE;
    border-top: 15px solid #006F77;
    border-radius: 10px;
    box-shadow: 4px 5px 5px 0px #8D8D8D;
    padding-top: 20px;
    /* margin-bottom: 20px; */

    min-width: 300px;
    width: 25%;
    margin: 20px auto;
}
.box div{
    background-repeat: no-repeat;
    background-position: top;
    width:80px;
    height: 80px;
}
.box div.missao {
    background-image: url('../../../_assets/img/icones/missão icon.png');
}
.box div.valores {
    background-image: url('../../../_assets/img/icones/valores icon.png');
}
.box div.visao {
    background-image: url('../../../_assets/img/icones/visão icon.png');
}

.box p{
    width: 100%;
    margin: 0;
}

.box p.titulo {
    align-self: flex-start;
    /* height: 70px; */
    font-weight: 800;
    font-size: 40px;
    color:#006F77;
    text-align: center;
    /* line-height: 30px; */
}
.box p.texto {
    font-size: 20px;
    text-align: left;
    padding: 0 10px;
    overflow: hidden;
}

@media (min-width: 632px) {
    .box p.texto {
        min-height: 290px;
    }
}