#carrossel-ds .carousel-indicators{
    margin-right: 20%;
    margin-bottom: 1rem;
    margin-left: 20%;
}
#carrossel-ds .carousel-indicators button{
    width: 20px;
    height: 20px;
    opacity: 1;
    border: 3px solid #fff;
    background-clip: inherit;
    background-color: #C4C4C4;
}
#carrossel-ds .carousel-indicators button.active {
    background-color: #006F77;
    border: 3px solid #fff;
}
#carrossel-ds .carousel-control-prev .carousel-control-prev-icon, #carrossel-ds .carousel-control-next .carousel-control-next-icon {
    width: 4rem;
    height: 4rem;
}
#carrossel-ds .carousel-control-prev .carousel-control-prev-icon{
    background-image: url('../../_assets/img/icones/botão\ volta.png');
}
#carrossel-ds .carousel-control-next .carousel-control-next-icon{
    background-image: url('../../_assets/img/icones/botão\ vai.png');
}
#carrossel-ds .carousel-caption{
    bottom: 3.5rem;
}
#carrossel-ds .carousel-inner .carousel-item .titulo-carrossel{
    font-weight: 900;
    font-size: 40px;
}
#carrossel-ds .carousel-inner .carousel-item .img-ver-mais{
    width: 180px;
}

@media (max-width: 768px) {
    #carrossel-ds .carousel-indicators button {
        width: 15px;
        height: 15px;
    }
    #carrossel-ds .carousel-control-prev .carousel-control-prev-icon,
    #carrossel-ds .carousel-control-next .carousel-control-next-icon {
        width: 3rem;
        height: 3rem;
    }
    #carrossel-ds .carousel-inner .carousel-item .titulo-carrossel {
        font-size: 30px;
    }
    #carrossel-ds .carousel-inner .carousel-item .img-ver-mais{
        width: 120px;
    }
}
@media (max-width: 450px) {
    #carrossel-ds .carousel-control-prev .carousel-control-prev-icon,
    #carrossel-ds .carousel-control-next .carousel-control-next-icon {
        width: 2rem;
        height: 2rem;
    }
    #carrossel-ds .carousel-inner .carousel-item .titulo-carrossel {
        font-size: 15px;
    }
    #carrossel-ds .carousel-inner .carousel-item .img-ver-mais{
        width: 60px;
    }
    #carrossel-ds .carousel-caption {
        bottom: 2rem;
    }
}