.items {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.items nav.links {
    width: auto;
    display: block;
    margin: 0;
}

nav.links div.menu {
    gap: 50px;
    justify-content: space-between;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

nav.links h1.logo{
    margin: 0;
    padding: 0;
    height: auto;
}

nav.links h1.logo .logo-img{
    width:225px;
}
nav.links div.menu .collapse{
    justify-content: flex-end;
}
nav.links ul.links-list{
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 10px;
    width: 100%;
}

nav.links ul.links-list li.col {
    flex-flow: row nowrap;
    list-style: none;
    height: 90px;
    display:flex;
}
nav.links ul.links-list li.col a.link{
    align-self: center;
    cursor: pointer;
    color:#00353f;
    text-decoration: none;
    font-weight: 500;
    padding:5px 8px;
    margin: 0;
    text-align: center;
    font-size: 24px;
    /* font-size: calc(1.275rem + 0.3vw); */
}


@media (min-width: 1200px) {
    nav.links ul.links-list {
        gap: 50px;
    }
}
@media (max-width: 993px) {
    /* .items{
        padding:0 !important;
    } */
    .items nav.links {
        width: 100%;
    }
    nav.links div.menu{
        padding: 0 !important;
        gap: 20px;
    }
    nav.links ul.links-list {
        gap: 10px !important;
    }
    nav.links ul.links-list li.col {
        width: 100%;
        justify-content: center;
    }
    nav.links ul.links-list li.col a.link {
        padding: 5px 0px;
        width: 100%;
        font-size: 18px;
        /* font-size: calc(1rem + 0.3vw); */
    }
}
@media (max-width: 767px) {
    nav.links ul.links-list {
        flex-flow: column nowrap;
    }
    nav.links .logo-img {
        width: 200px !important;
    }
}
@media (max-width: 400px) {
    nav.links .logo-img {
        width: 150px !important;
    }
}
