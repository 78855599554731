section.sobre{
    width: 100%;
}
section.sobre .row.linha-texto {
    flex-flow: row wrap;
    justify-content: space-between;
    /* justify-content: center; */
    /* justify-content:space-around; */
}
section.sobre .titulo-secao{
    padding: 15px;
    margin: 0;
}
section.sobre .titulo-secao span{
    font-size: 48px;
    font-weight: 800;
    font-style: normal;
}
section.sobre .texto{
    width: 45%;
    overflow: hidden;
    /* margin: 0 auto; */
}
section.sobre .texto p{
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    text-align: justify;
}
section.sobre .imagem-sobre{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: center;
    /* margin: 0 auto; */
}
section.sobre .imagem-sobre .img-sobre{
    width: 100%;
    max-width: 600px;
}
@media (min-width: 1400px) {
    section.sobre .texto p{
        font-size: 24px;
    }
}
@media (max-width: 992px) {
    section.sobre .titulo-secao span {
        font-size: 40px;
    }
    section.sobre .texto {
        width: 90%;
    }
    section.sobre .imagem-sobre{
        display:none;
    }
}