main.conteudo{
    background-image: url('../../_assets/img/outras\ imagens/white back.png');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    display: flex;
    flex-direction: column;
}

main.conteudo .titulo-normal{
    color: #000;
    font-weight: 800;
}

main.conteudo .titulo-verde{
    font-weight: 800;
    color:#006F77;
}
main.conteudo .titulo-branco{
    font-weight: 800;
    color:#FFF;
}


.missao-valores-visao .row{
    justify-content: center;
    flex-flow: row wrap;
    /* justify-content:space-around; */
}