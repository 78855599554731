section.clientes .titulo-secao span {
    font-size: 48px;
    font-style: normal;
    text-align: center;
}

section.clientes .linha-borda{
    justify-content: center;
}
section.clientes .linha-borda .linha-verde{
    border-bottom: 5px solid #006F77;
    width: 300px;
}

section.clientes .lista-clientes {
    display: flex;
    justify-content: space-around;
    list-style: none;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
}

section.clientes .lista-clientes .cliente {
    width: 30%;
    display: flex;
    justify-content: center;
    min-width: 275px;
}

section.clientes .lista-clientes .cliente .link-img-cliente {
    /*     background-size: cover; */
    background-repeat: no-repeat;
    background-size: 255px;
    height: 325px;
    width: 275px;
}

section.clientes .lista-clientes .cliente .link-img-cliente.pibv{
    background-image: url(../../../_assets/img/clientes/Logo\ igreja\ nova.png);
    background-position: center;
}
section.clientes .lista-clientes .cliente .link-img-cliente.support{
    background-image: url(../../../_assets/img/clientes/logoMM.png);
    background-position: top;
}
section.clientes .lista-clientes .cliente .link-img-cliente.mmartins{
    background-image: url(../../../_assets/img/clientes/logoSupport2.png);
    background-position: center;
}
section.clientes .lista-clientes .cliente .link-img-cliente.adns{
    background-image: url(../../../_assets/img/clientes/Logo_ADNS.png);
    background-position: center;
}
section.clientes .lista-clientes .cliente .link-img-cliente.pibbds{
    background-image: url(../../../_assets/img/clientes/logoPIBBarraDoSahy.png);
    background-position: center;
}
section.clientes .lista-clientes .cliente .link-img-cliente.movement{
    background-image: url(../../../_assets/img/clientes/LOGO-MOVEMENT-1-1.png);
    background-position: center;
}

section.clientes .lista-clientes .cliente .img-cliente {
    max-width: 275px;
}

@media (max-width: 448px) {
    section.clientes .titulo-secao span {
        font-size: 30px;
    }
}